<template>
  <div>
    <portal to="subheader_actions">
      <b-button class="mr-2 openModalFormPeriod" @click="openModal">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(enterprise)="data">
                  {{ findEnterprise(data.item.enterprise) }}
                </template>
                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      class="openModalFormPeriod"
                      @click="editItem(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button
                      @click="removeItem(data)"
                      size="sm"
                      variant="light"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(period_code_rep)="data">
                  <v-chip
                    class="ma-2"
                    v-bind:style="{
                      'background-period': data.item.period_code
                    }"
                  >
                    ----
                  </v-chip>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
    <modal-form-period
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      v-on:toast="openToast"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
      :enterprises="enterprises"
      selectorToggle=".openModalFormPeriod"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ModalFormPeriod from "./ModalFormPeriod";

export default {
  data() {
    return {
      showModal: false,
      currentObject: {},
      currentPage: 1,
      perPage: 12,
      items: [],
      loaded: false,
      enterprises: [],
      fields: [
        { key: "actions", label: "Acciones" },
        {
          key: "period",
          sortable: true,
          label: "Cód"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "enterprise",
          sortable: true,
          label: "Empresa"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    findEnterprise(enterpriseId) {
      let enterprise = this.custom_lodash.find(this.enterprises, ["id", enterpriseId]);
      if (enterprise === undefined) {
        return "";
      }
      return enterprise.name;
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    listItems() {
      ApiService.get("api/period").then(({ data }) => {
        this.items = data;
      });
    },
    editItem(data) {
      this.currentObject = data.item;
      this.openModal();
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },
    removeItem({ item: { id } }) {
      Swal.fire({
        title: "",
        text: "¿Está seguro de eliminar el Period?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        confirmButtonText: "Sí, estoy seguro.",
        confirmButtonClass: "btn btn-secondary",
        cancelButtonText: "Cancelar"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          ApiService.delete(`/api/period/${id}/`)
            .then(() => {
              this.openToast({
                message: "Se eliminó correctamente el periodo",
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.items = this.items.filter((el) => el.id !== id);
            })
            .catch(() => {
              this.openToast({
                message: "Este periodo tiene documentos, no se puede eliminar",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            });
        }
      });
    },
    onCreated(object) {
      this.items.unshift(object);
    },
    onUpdated(object) {
      this.items = this.items.map((data) => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    },
    getEnterprises() {
      ApiService.get("api/enterprise").then(({ data }) => {
        this.enterprises = data;
      });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Maestros" },
      { title: "Periodos" }
    ]);
    this.getEnterprises();
  },
  components: {
    ModalFormPeriod
  }
};
</script>
