<template>
  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-period"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>
    <div class="justify-content-center">
      <form class="full" id="formSubproject" ref="formSubproject">
        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
        >{{ error }}
        </b-alert>
        <trazo-form-input
          id="input-mont_year"
          v-model="mont_year"
          name="mont_year"
          label="Fecha"
          :required="true"
          labelPosition="top"
          type="month"
          :disabled="
            objForm !== null && !custom_lodash.isEmpty(objForm)
          "
        />
        <trazo-form-input
          id="input-description"
          v-model="description"
          name="description"
          label="Descripción"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Empresa"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(enterprise)
            }"
          >
            <a-multiselect
              :disabled="
                objForm !== null && !custom_lodash.isEmpty(objForm)
              "
              name="enterprise"
              track-by="id"
              label="name"
              v-model="enterprise"
              placeholder="Seleccione una empresa"
              :options="enterprises"
              :allow-empty="false"
              @select="onSelect($event, 'enterprise')"
            />
            <input
              type="hidden"
              name="enterprise_hidden"
              v-model="enterprise_hidden"
            />
          </div>
        </trazo-form-label-slot>
      </form>
    </div>
  </trazo-off-canvas>
</template>

<script>
import Vue from "vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";

import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";

function initialState() {
  return {
    sending: false,
    textModal: "NUEVO PERIODO",
    mont_year: "",
    description: "",
    enterprise: "",
    enterprise_hidden: "",
    id: "",
    errors: [],
    edit: false
  };
}

export default Vue.component("modal-form-period", {
  data() {
    return initialState();
  },
  props: ["objForm", "isOpen", "selectorToggle", "enterprises"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    }
  },
  methods: {
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;
        this.errors = [];
        this.sending = true;
        saveFunction(`api/period/${this.id ? this.id + "/" : ""}`, {
          period: this.mont_year,
          enterprise: this.enterprise_hidden,
          description: this.description.trim()
        })
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.closeModal();
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach(object => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        this.id = this.objForm.id;
        this.mont_year =
          this.objForm.period.substring(0, 4) +
          "-" +
          this.objForm.period.substring(4);
        this.description = this.objForm.description + "";
        this.enterprise = this.enterprises.find(
          el => el.id === this.objForm.enterprise
        );
        this.enterprise_hidden = this.enterprise.id;
        this.textModal = "EDITAR PERIODO";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          mont_year: {
            validators: {
              notEmpty: {
                message: "La fecha es obligatoria"
              }
            }
          },
          description: {
            validators: {
              notEmpty: {
                message: "La descripción es obligatorio"
              }
            }
          },
          enterprise_hidden: {
            validators: {
              notEmpty: {
                message: "La empresa es obligatorio"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
      this.fv.validate();
    },
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    TrazoFormInput,
    TrazoOffCanvas,
    TrazoFormLabelSlot
  }
});
</script>
